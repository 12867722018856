:root,
:root [data-theme='old-theme'],
:root [data-theme='default-theme'],
:root [data-theme='dark-theme'] {
  /* Не вписанные в спеку */
  --filter-bg: var(--glz-color-neutral-tone-1);
  --filter-bg-dark: var(--glz-color-neutral-tone-2);
  --stiky-tabs-bg: var(--glz-color-neutral);
  --lighter-gray: var(--glz-color-neutral);

  /* Размерные */
  --body-paddings: 20px;
}

@media (max-width: 890px) {
  :root,
  :root [data-theme='old-theme'],
  :root [data-theme='default-theme'],
  :root [data-theme='dark-theme'] {
    --body-paddings: 8px;
  }
}
