.container {
  width: 100%;
  display: flex;
  min-height: 60vh;
}

.errorBoundary {
  display: flex;
  margin: auto;
  flex-direction: column;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
}

.heading {
  margin-top: 18px;
  margin-bottom: 0;
}

.desc {
  text-align: center;
  max-width: 420px;
}
