@font-face {
  font-family: 'ATI Icons';
  src:
    url('//files.ati.su/Fonts/ati-icons.eot?#iefix') format('embedded-opentype'),
    url('//files.ati.su/Fonts/ati-icons.ttf') format('truetype'),
    url('//files.ati.su/Fonts/ati-icons.woff') format('woff');
}

html,
body {
  box-sizing: border-box;
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: Verdana, 'Dejavu Sans', Geneva, Tahoma, sans-serif;
  color: var(--glz-color-neutral-tone-5);
  text-size-adjust: none;
  background-color: var(--glz-color-neutral-tone-0);
}

*,
::after,
::before {
  box-sizing: inherit;
}

a {
  color: inherit;
  text-decoration: none;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
  color: var(--glz-color-neutral-tone-5);
}

button {
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  color: var(--glz-color-neutral-tone-5);
  outline: none;
  font-family: Verdana, 'Dejavu Sans', Geneva, Tahoma, sans-serif;
}

iframe {
  border: none;
}

#page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

#root {
  display: flex;
  flex: 1 0 auto;
}

/* stylelint-disable-next-line selector-id-pattern */
#__next {
  display: flex;
  flex-grow: 1;
  max-width: 100%;
}

.hidden {
  display: none !important;
}

.errno-h {
  position: fixed;
  display: flex;
  justify-content: center;
  flex-direction: column;
  top: -150px;
  left: 0;
  color: #39444f;
  font-family: Verdana, sans-serif;
  font-size: 16px;
  width: 100%;
  padding: 12px 60px 12px 24px;
  box-sizing: border-box;
  transition-duration: 0.3s;
  transition-property: 'top';
  text-align: center;
  z-index: 10500;
}

.errno-close-icon {
  cursor: pointer;
  position: absolute;
  right: 27px;
  width: 24px;
  height: 24px;
  background-color: transparent;
  border: none;
  display: block;
  box-sizing: border-box;
  padding: 0;
}

.errno-text {
  min-height: 24px;
}

.errno-error,
.errno-error-old,
.errno-success,
.errno-success-old,
.errno-warning,
.errno-warning-old {
  top: 0;
}

.errno-error-old,
.errno-success-old,
.errno-warning-old {
  color: #ffffff;
  opacity: 0.9;
}

.errno-error-old .errno-close-icon path,
.errno-success-old .errno-close-icon path,
.errno-warning-old .errno-close-icon path {
  fill: #ffffff;
}

.errno-error {
  background-color: #ffd6dd;
}

.errno-warning {
  background-color: #ffecc0;
}

.errno-success {
  background-color: #c1e3aa;
}

.errno-error-old {
  background-color: #d0021b;
}

.errno-warning-old {
  background-color: #e6ba4d;
}

.errno-success-old {
  background-color: #64b82a;
}

.errno-close-icon:hover {
  opacity: 0.5;
}

div[data-elem='select-list'] {
  z-index: 10350 !important;
}
